import React, { useState } from "react";
// react redux
import { useSelector } from "react-redux";
// mui
import { IconButton } from "@mui/material";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosError, AxiosResponse } from "axios";
// types
import { ReduxUserReducerState } from "../../types/global";

// icons
import {
    IoMdCloseCircleOutline,
    IoIosCheckmarkCircleOutline,
} from "react-icons/io";
import {
    askForConfirmationAlert,
    successAlert,
    warningAlert,
} from "../../utils/alerts/alerts";

type Props = {
    transactionId: string;
    isConfirmed: boolean;
};

const ConfirmTransactionButton = ({ transactionId, isConfirmed }: Props) => {
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [submitting, setSubmitting] = useState(false);
    const [confirmed, setConfirmed] = useState(isConfirmed);
    const confirm = () => {
        EnhancedAxios(null, userToken)
            .put(
                `/admin/wallet-transactions/${transactionId}`,
                JSON.stringify({ is_confirmed: true })
            )
            .then((res: AxiosResponse) => {
                successAlert(`تم تأكيد العملية `);
                setSubmitting(false);
                setConfirmed(true);
            })
            .catch((error: AxiosError) => {
                setSubmitting(false);
            });
    };
    const checkConfirm = () => {
        if (confirmed) {
            warningAlert("العملية مؤكدة بالفعل");
        } else {
            askForConfirmationAlert(
                "هل انت متاكد انك تريد تاكيد العملية ",
                confirm
            );
        }
    };
    // admin/wallet-transactions/{{wallet_transaction_id}}
    return (
        <IconButton
            color={confirmed ? "success" : "error"}
            disabled={submitting}
            onClick={checkConfirm}
        >
            {confirmed ? (
                <IoIosCheckmarkCircleOutline />
            ) : (
                <IoMdCloseCircleOutline />
            )}
        </IconButton>
    );
};

export default ConfirmTransactionButton;
// (params.row?.is_confirmed ? "✅" : "❌")
